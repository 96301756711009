.dashboard {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header header"
    "aside children";
  min-height: 100vh;
  min-width: 100vw;
}
.dashboard header,
.dashboard aside {
  background-color: #212739;
  color: white;
}
.dashboard header {
  grid-area: header;
  padding: 19px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.dashboard header img {
  width: 132px;
  height: auto;
}
.dashboard header button {
  background: transparent;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 12px;
}
.dashboard header button:hover {
  background: #69728f;
}
.dashboard header button img {
  width: 24px;
  height: auto;
  margin-right: 16px;
}
.dashboard aside {
  grid-area: aside;
  display: flex;
  flex-direction: column;
  padding: 13px;
}
.dashboard aside a {
  padding: 18px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  border-radius: 6px;
  white-space: nowrap;
}
.dashboard aside a:hover {
  text-decoration: underline !important;
}
.dashboard aside a.selected {
  background: #69728f;
}
.dashboard aside a img {
  width: 24px;
  height: auto;
  margin-right: 12px;
}
.dashboard .nav-children {
  grid-area: children;
  overflow-x: auto;
  overflow-y: scroll;
}

.logo-hamburger-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

button.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 1rem;
  padding: 0;
}

.hamburger-line {
  width: 30px;
  height: 3px;
  border-radius: 2px;
  background-color: white;
  margin: 3px 0;
}
button.close-aside {
  align-self: flex-start;
  margin-bottom: 3rem;
  background-color: transparent;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50px;
  height: 50px;
  position: relative;
  margin-top: 6px;
}
button.close-aside:hover {
  background: #69728f;
}
.x-line {
  position: absolute;
  width: 70%;
  height: 3px;
  border-radius: 2px;
  background-color: white;
}
.x-line:first-child {
  transform: rotate(45deg);
}
.x-line:last-child {
  transform: rotate(-45deg);
}

@media screen and (max-width: 1260px) {
  .dashboard {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "header"
      "children";
  }
  .dashboard aside {
    grid-area: initial;
    position: absolute;
    z-index: 300;
    transition: all 0.3s ease;
  }
}
