.funnel-chart-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.funnel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  list-style: none;
}
.funnel > * {
  margin: 2.2px 0;
}
.funnel li:nth-child(1) {
  width: 245px;
  height: 103.33px;
  border-top: 103.33px solid #dddee0;
  border-left: 35px solid #fff;
  border-right: 35px solid #fff;
}
.funnel li:nth-child(2) {
  width: 172px;
  height: 31.11px;
  border-top: 31.11px solid #0038a8;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
}
.funnel li:nth-child(3) {
  width: 152px;
  height: 16.67px;
  border-top: 16.67px solid #b80290;
}
