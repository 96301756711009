button {
  border: none;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 100% !important;
  text-align: center;
  padding: 12px 24px;
}
button.primary {
  background-color: #353e5a;
  color: white;
}
button.primary:hover {
  background: #69728f;
}
button.primary:active {
  background: #212739;
}
button.primary:disabled {
  background: #69728f;
  opacity: 0.5;
}
button.secondary {
  background: #ffffff;
  border: 1px solid #353e5a;
  color: #353e5a;
}
button.secondary:hover {
  background: #dddee0;
}
button.secondary:active {
  background: #dddee0;
}
button.secondary:disabled {
  opacity: 0.5;
  border: 1px solid #353e5a;
}
a.button {
  border: none;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 100% !important;
  text-align: center;
  padding: 12px 24px;
  text-decoration: none !important;
}
a.alternate,
button.alternate {
  background: #0038a8;
  color: white;
}
a.alternate:hover,
button.alternate:hover {
  background: #6580b5;
  color: white;
}
a.alternate:active,
button.alternate:active {
  background: #012a7b;
  color: white;
}
a.alternate:disabled,
button.alternate:disabled {
  background: #69728f;
  opacity: 0.5;
}
a img,
button img {
  width: 1rem;
  height: auto;
}
a.icon-l img,
button.icon-l img {
  margin-right: 12px;
}
a.alternate-light,
button.alternate-light {
  color: #0038a8;
  background: transparent;
}
a.alternate-light:hover,
b.alternate-light:hover {
  text-decoration: uneline !important;
}
a.alternate-light:disabled,
b.alternate-light:disabled {
  opacity: 0.7;
}
