.card {
  /* align-self: center; */
  border: none !important;
  margin: 0 2rem;
  /* grid-area: left; */
}

.cardBody {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none !important;
}

.small-text {
  font-size: 1.2rem;
}

.chart-side {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "center";
  align-items: center;
}

.radialChart {
  color: white;
  grid-area: center;
}

.total {
  grid-area: center;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.total h2 {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 100%;
  color: #030e31;
}
.total p {
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  color: #69728f;
}
