.new-legend {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 1.1rem;
}

.each-legend {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 1rem 0;
  width: max-content;
}

.each-legend .details {
  display: flex;
  flex-direction: column;
}

.label {
  height: 16px !important;
  width: 16px !important;
  margin: 0.5rem 16px;
}

.text {
  margin-bottom: 8px !important;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
}

.value {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
}
