.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 1rem;
  padding: 2rem 3rem;
  background: #f8f9fa;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.auth-form {
  width: 25rem;
  margin: 10rem auto;
}
.form hr {
  width: 100%;
  margin-bottom: 2rem;
}
.form h2 {
  text-align: center;
  margin-bottom: 0.75rem;
}
.form h5 {
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
}
p.error {
    color: #bd0101;
    text-align: center;
  }