.section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.breakdown-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.breakdown-wrapper h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
}
.total-banner {
  background: #ebeff8;
  border-radius: 6px;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}
.total-banner h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 100%;
  margin: 16px 0;
}
.total-banner p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
}
