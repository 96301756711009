.map-filter-buttons {
  display: flex;
  margin: -8px 0 16px; 
}
.map-filter-buttons button {
  color: #353e5a;
  background: white;
  border: 1px solid #353e5a;
}
.map-filter-buttons button:first-child {
  border-radius: 4px 0px 0px 4px !important;
}
.map-filter-buttons button:last-child {
  border-radius: 0 4px 4px 0 !important;
}
.map-filter-buttons button.map-filter-button-selected {
  background: #353e5a;
  color: white;
}
.map-tooltip {
  opacity: 1 !important;
  background-color: #ffffff !important;
  color: #353e5a !important;
  padding: 12px !important;
}
.map-tooltip-children {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: red !important; */
}
.map-tooltip-children p.name {
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #353e5a;
  margin-bottom: 8px !important;
}
.map-tooltip-children p.count {
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  color: #030e31;
  margin: 0 !important;
}

.map-container {
  position: relative;

  svg {
    cursor: grab;
  }
  svg.dragging {
    cursor: grabbing;
  }

  .controls {
    position: absolute;
    bottom: 8px;
    left: 8px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    display: grid;
    align-items: center;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: 1fr;
    background: white;
    border-radius: 4px;

    .btn {
      // margin: 0 6px;
      height: 100%;
      text-align: center;
      border: none;
      background: #ffffff;
      border-radius: 4px;
      color: #353e5a;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-zoom {
      // width: 26px;
      font-size: 22px;
    }

    .divider {
      border-left: 1px solid #cdcfd6;
      // transform: rotate(90deg);
      height: 27px;
    }

    /* input[type="range"]:focus,
input[type="number"]:focus {
  box-shadow: 0 0 3px 1px #4b81dd;
  outline: none;
} */

    input[type="range"] {
      padding: 0 !important;
      margin: 12px;
    }

    input[type="range"] {
      -webkit-appearance: none;
      margin-right: 15px;
      // width: 200px;
      height: 1px;
      // border-top: 1px solid #848484;
      background: rgba(255, 255, 255, 0.6);
      // border-radius: 5px;
      background-image: linear-gradient(#002e8a, #002e8a);
      background-size: 50% 100%;
      background-repeat: no-repeat;
    }

    /* Input Thumb */
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #002e8a;
      cursor: ew-resize;
      box-shadow: 0 0 2px 0 #555;
      transition: background 0.3s ease-in-out;
    }

    input[type="range"]::-moz-range-thumb {
      -webkit-appearance: none;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #002e8a;
      cursor: ew-resize;
      box-shadow: 0 0 2px 0 #555;
      transition: background 0.3s ease-in-out;
    }

    input[type="range"]::-ms-thumb {
      -webkit-appearance: none;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #002e8a;
      cursor: ew-resize;
      box-shadow: 0 0 2px 0 #555;
      transition: background 0.3s ease-in-out;
    }

    input[type="range"]::-webkit-slider-thumb:hover {
      background: #002e8a;
    }

    input[type="range"]::-moz-range-thumb:hover {
      background: #002e8a;
    }

    input[type="range"]::-ms-thumb:hover {
      background: #002e8a;
    }

    input[type="range"]::-webkit-slider-thumb:focus {
      border: 4px solid red;
    }

    /* Input Track */
    input[type="range"]::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    input[type="range"]::-moz-range-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    input[type="range"]::-ms-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }

  .map-legend {
    background: #ffffff;
    opacity: 0.9;
    border-radius: 8px;
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 12px;
    .scale {
      background: linear-gradient(-90deg, #0038a8 0%, #ffffff 100%);
      border: 0.5px solid #dddee0;
      height: 20px;
      width: 120px;
      margin: 0 12px;
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 100%;
      color: #353e5a;
    }
  }
}
