.two-split-grid {
  display: grid;
  grid-gap: 22px;
  grid-template-columns: minmax(auto, 50%) minmax(auto, 50%);
  grid-template-rows: 1fr;
}
.two-split-grid > * {
  /* max-height: 572px; */
}

@media screen and (max-width: 1260px) {
  .two-split-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}
