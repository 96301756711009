.box {
  background: #ffffff;
  box-shadow: 0px 3px 12px rgba(185, 185, 185, 0.2);
  border-radius: 8px;
  padding: 1rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  overflow-x: scroll;
}

.box h2 {
  margin: 1rem 0 2rem;
}

.divided-box {
  padding: 23px 0 !important;
}

.divided-box .divided-box-header {
  border-bottom: 1px solid #dddee0;
  padding: 0 2rem 23px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.divided-box .divided-box-header.two-rows {
  grid-gap: 1rem !important;
  grid-template-rows: auto auto !important;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.divided-box .divided-box-header h3 {
  margin: 0;
}

.divided-box .divided-box-content {
  padding: 24px 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.divided-box .has-table {
  margin-top: -40px;
}

.divided-box .row-content {
  display: grid !important;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  justify-items: start;
}

@media screen and (max-width: 1260px) {
  .divided-box .row-content {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}

.divided-box .undo-top-margin {
  margin-top: -55px !important;
}
.divided-box .undo-top-margin-default {
  margin-top: -24px !important;
}

.divided-box .link-row {
  border-top: 1px solid #dddee0;
  padding-top: 16px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.divided-box .link-row a > span {
  margin-right: 11px;
}
.divided-box .link-row a > img {
  height: 12px;
  width: auto;
}
