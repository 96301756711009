.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 2rem;
  flex-wrap: wrap;
}

.header .controls {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: auto auto auto;
}

@media screen and (max-width: 400px) {
  .header .controls {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(186px, auto));
  }
}

.list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(186px, auto));
}
.list > * {
  height: 200px;
}
.brand-box {
  background: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #353e5a;
  border-radius: 6px;
  padding: 1rem 2rem;
  text-align: center;
}
.brand-box h5 {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
