* {
  color: #030e31;
}

h2 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 36px !important;
}

h3 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 100% !important;
}

a {
  text-decoration: none !important;
  display: flex;
  align-items: center;
  color: #0038a8;
}
a:hover {
  text-decoration: underline !important;
}
a * {
  color: #0038a8 !important;
}
