.empty-state-wrapper {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #030e31;
  text-align: center;
  padding: 66px;
  position: relative;
}

.empty-state-wrapper h3.title {
  position: absolute;
  top: 25px;
  left: 0;
}

.empty-state-wrapper img {
  margin: 12px 0 54px;
}

.empty-state-wrapper h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  margin-bottom: 16px;
}

.empty-state-wrapper p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
}
