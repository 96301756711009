.tab-row {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  border-bottom: 1px solid #bdbec3;
  margin: 0 0 40px;
}
.tab-row button {
  background: transparent;
  border-radius: 0 !important;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 100%;
  color: #030e31;
  padding: 20px 0;
  margin-right: 48px;
}
.tab-row .selected-tab {
  border-bottom: solid 4px #030e31;
  font-weight: 500;
}
