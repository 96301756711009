.location-selector {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  grid-template-rows: 1fr;
  position: relative;
  justify-items: end;

  .selector {
    position: relative;
  }

  button {
    background: #ffffff;
    border: 1px solid #dddee0;
    box-sizing: border-box;
    border-radius: 4px !important;
    padding: 8px !important;

    display: flex;
    align-items: center;

    p.selection-title {
      color: #858993;
    }
    p.selection {
      margin: 0 0.5rem !important;
      color: #0038a8;
    }
    img {
      width: 12px !important;
      height: auto;
    }
    .selected-arrow {
      transform: rotate(-180deg);
    }
  }

  .dropdown-list {
    position: absolute;
    background-color: white;
    border: 1px solid #dddee0;
    // border-top: none;
    box-shadow: 0px 10px 8px -4px rgba(53, 62, 90, 0.2);
    border-radius: 6px;
    top: 40px;
    left: 0;
    width: max-content;
    // top: 40px;
    // left: 0;
    // width: 100.2%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 6px 7px;
    z-index: 100;

    button {
      background: none !important;
      border: none !important;
    }
    button:hover {
      background-color: rgb(0, 0, 0, 0.1) !important;
      border-radius: 6px;
    }
  }
}
