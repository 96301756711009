@font-face {
  font-family: "Poppins";
  src: url("Assets/fonts/Poppins/Poppins-Medium.woff2") format("woff2"),
    url("Assets/fonts/Poppins/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("Assets/fonts/Poppins/Poppins-Regular.woff2") format("woff2"),
    url("Assets/fonts/Poppins/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("Assets/fonts/Poppins/Poppins-Light.woff2") format("woff2"),
    url("Assets/fonts/Poppins/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
