.page-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}
.page-header .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 160px;
  padding: 0 40px;
  background-color: #ffffff;
}
.dropdown-wrapper {
  background-color: #353e5a;
  display: grid;
  grid-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  grid-template-areas: "title dropdown";
  align-items: center;
  padding: 16px 43px;
  height: 78px;
}
.dropdown-wrapper h3 {
  grid-area: title;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  margin: 0;
}
.dropdown-wrapper .dropdown {
  grid-area: dropdown;
  position: relative;
  background-color: white;
  border: 1px solid #bdbec3;
  box-sizing: border-box;
  z-index: 200;
  /* Item Shadow */

  box-shadow: 0px 3px 12px rgba(185, 185, 185, 0.2);
  border-radius: 6px;
}
.dropdown-wrapper .selected-dropdown {
  border: 1px solid #353e5a;
}
.dropdown-wrapper .dropdown h4 {
  color: #0038a8;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  margin: 0;
}
.dropdown-wrapper .dropdown button {
  border: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
}
.dropdown-wrapper .dropdown button img {
  width: 18px;
  height: auto;
}
.arrow {
  /* transition: all ease 0.3s; */
}
.selected-arrow {
  transform: rotate(-180deg);
  /* transition: all ease 0.3s; */
}
.dropdown-wrapper .dropdown .dropdown-list {
  position: absolute;
  background-color: white;
  border: 1px solid #353e5a;
  border-top: none;
  box-shadow: 0px 10px 8px -4px rgba(53, 62, 90, 0.2);
  border-radius: 0 0 6px 6px;
  top: 40px;
  right: -0.5px;
  width: 100.2%;
  display: flex;
  flex-direction: column;
  padding: 12px 14px;
}
.dropdown-wrapper .dropdown a {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  color: #353e5a;
  text-decoration: none;
  padding: 14px 10px;
}
.dropdown-wrapper .dropdown a:hover {
  background-color: rgb(0, 0, 0, 0.1);
  border-radius: 6px;
  text-decoration: underline !important;
}

.dropdown-wrapper .dropdown a.selected-link {
  color: #0038a8;
  font-weight: 500;
}
