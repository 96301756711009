.summary-box {
  border-radius: 6px;
  background: #ffffff;
  color: #353e5a;
  display: grid;
  grid-gap: 2px;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "icon value"
    "icon text";
  align-items: center;
  min-height: 91px;
  padding: 22px 16px;
}
.summary-box img {
  grid-area: icon;
  width: 48px;
  height: auto;
  margin-right: 14px;
}
.summary-box h3,
p {
  margin: 0 !important; 
}
.summary-box h3 {
  grid-area: value;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
}
.summary-box p {
  grid-area: text;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
}
.green {
  border: 1px solid #008080;
}
.green h3 {
  color: #008080;
}
.blue {
  border: 1px solid #017eb4;
}
.blue h3 {
  color: #017eb4;
}
.purple {
  border: 1px solid #5600ac;
}
.purple h3 {
  color: #5600ac;
}
.pink {
  border: 1px solid #b80290;
}
.pink h3 {
  color: #b80290;
}
