.my-tooltip-wrapper {
  position: relative;
  margin-left: 0.5rem !important;
}
.my-tooltip-wrapper img:hover {
  cursor: pointer;
}
.my-tooltip {
  position: absolute;
  bottom: 2.25rem;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  background: white;
  z-index: 100;
  width: 213px;
  min-height: 96px;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(40, 41, 61, 0.12),
    -2px 20px 32px rgba(96, 97, 112, 0.24);
}
.my-tooltip:after {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  border-top: solid 10px white;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}
.my-tooltip p {
  white-space: normal !important;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
}
