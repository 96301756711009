.summary-row {
  display: grid;
  grid-gap: 20px;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(330px, auto));
  align-items: center;
}

.summary-2x2 {
  display: grid;
  grid-gap: 20px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .summary-2x2 {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
}
