.heat-map-legend {
  height: 26px;
  margin: 20px 0 0;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.heat-map-legend .heat-map-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 210px;
}
.heat-map-legend-box {
  height: 12px;
  width: 50px;
  margin: 2px;
}
.heat-map-legend-box:nth-child(1) {
  background-color: #dddee0;
}
.heat-map-legend-box:nth-child(2) {
  background-color: #6580b5;
}
.heat-map-legend-box:nth-child(3) {
  background-color: #0038a8;
}
.heat-map-legend-box:nth-child(4) {
  background-color: #012a7b;
}
